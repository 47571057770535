import { AxiosResponse } from 'axios';
import { DeviceWizardCategory, DevicesAndSettingsResponse } from 'types/device';
import ExecuteApiCall, { ExecuteUnchangedApiCall } from './apiClient';

export function fetchDevicesAndSettings(): Promise<AxiosResponse<DevicesAndSettingsResponse>> {
  return ExecuteApiCall('get', 'api/v3/devices_and_settings');
}

export function fetchDevices(params?: Record<string, unknown>) {
  return ExecuteApiCall('get', 'api/v2/device_settings/devices', params);
}

export function fetchPumpSettings(params?: Record<string, unknown>) {
  return ExecuteApiCall('get', 'api/v2/device_settings/pump_settings', params);
}

export function fetchMeterTimeOffsets(params?: Record<string, unknown>) {
  return ExecuteApiCall('get', 'api/v3/devices/time_offsets', params);
}

export function fetchDeviceCategoryTree(): Promise<AxiosResponse<DeviceWizardCategory[]>> {
  return ExecuteUnchangedApiCall('get', 'api/v2/device_category_tree');
}
